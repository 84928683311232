import { ShoppbudAPI } from '@inhouse-market/sdk';

import { endpoints } from "../env";

import pkg from "../../app.json";

import AsyncStorage from "@react-native-async-storage/async-storage";

export * from '@inhouse-market/sdk';

const apiSDK = new ShoppbudAPI({
  async getTokenFn() {
    const authToken = await AsyncStorage.getItem("@Authorization");
    return authToken!;
  },
  platformAndVersion: "web",
  appVersion: pkg?.expo?.version || '',
  url: endpoints.api,
});

export default apiSDK;
