import { NavigationProp, RouteProp, useIsFocused, useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import React, { useEffect, useMemo, useState } from "react";
import LoadingPage from "../../components/LoadingPage";
import ModalInfo from "../../components/ModalInfo";
import { useModal } from "../../hooks";
import { useCart } from "../../hooks/use-cart";
import { CopyPix } from "../../providers/components/copy-pix";
import { NumberToCurrency } from "../../utils/numberToCurrency";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useStore } from "../../hooks/use-store";
import { requestToken } from "../../services/firebase";
import {
	ButtonPaymentContainer,
	ChangeButton,
	ConfirmButton,
	Container,
	ContainerContentEmptyCart,
	Content,
	ContentButtonProducts,
	ContentEmptyCart,
	EmptyCartContainer,
	EmptyCartIcon,
	Label,
	NoItemsText,
	PayAtContainer,
	PixCodeContainer,
	PixCodeText,
	PixDetailsContainer,
	PixInfo,
	PixLogo,
	PixValueContainer,
	Scroll,
	TextAnother,
	TextChange,
	TextConfirmButton,
	Title,
	Value,
	ValueSecondary,
} from "./styles";
import HeaderNavigation from "../../components/HeaderNavigation";
import { ActivityIndicator, View } from "react-native";
import { PurchaseDetails } from "../../models/purchases";
import usePurchase from "../../store/store/usePurchase";
import { subscribeToEvent, unsubscribeFromEvent } from "../../services/socket";
import { SOCKET_COMMANDS } from "../../utils/socketCommands";

const logoPixIcon = require("../../../assets/logo_pix-big.png");
const CartEmpty = require("../../assets/cart_clean.svg");

interface RouteParams {
	key: string;
	name: string;
	cpf: string
	path: any;
}

type Navigation = NavigationProp<any>;

type ProductRouteProp = RouteProp<Record<string, RouteParams>, 'Pagar'>;

const Payment: React.FC<{ route?: ProductRouteProp }> = ({ route }) => {
	const [buttonText, setButtonText] = useState("Copiar código PIX");
	const [pixCode, setPixCode] = useState("");
	const [loading, setLoading] = useState(true);
	const [purchaseId, setPurchaseId] = useState<string | null>("");
	const [salleId, setSalleId] = useState<number | null>(null);
	const [modalPaid, setModalPaid] = useState(false);
	const [modalErrorPay, setModalErrorPay] = useState(false);
	const [modalAction, setModalAction] = useState<() => void>();
	const modal = useModal();
	const myCart = useCart();
	const navigation = useNavigation<Navigation>();
	const paramStore = useStore();
	const isFocused = useIsFocused();

	const {
		mutateAsync: mutationPurchase,
	} = usePurchase();

	const copyToClipboard = async () => {
		Clipboard.setStringAsync(pixCode);
		await modal.show(<CopyPix /> as any);
		setButtonText("Copiado!");
	};

	const handleNavigateTo = () => {
		navigation.navigate("Carrinho");
	};

	const cartItems = useMemo(() => myCart.getCart(), [myCart]);

	const generetaPixCode = async () => {
		const params = route?.params?.cpf
		const cpf = params && params?.replace(/[\.-]/g, '');
		const pwaId = await AsyncStorage.getItem("id_pwa");
		const pwaPush = await requestToken();

		try {
			const items = cartItems.map((item) => ({
				productName: item.name,
				planogramId: item.id,
				productId: item.product_id,
				price: item.final_price_in_cents,
				listPrice: item.last_price_in_cents,
				quantity: item.quantity,
			}));

			const response = await mutationPurchase({
				currentCpf: cpf || "00000000000",
				items,
				paymentMethod: "pix",
				paymentMethodId: null,
				status: "pending",
				storeId: paramStore.storeId,
				pwaId: String(pwaId),
				pwaPush,
			});

			if (response) {
				setPixCode(response?.pix);
				setPurchaseId(response?.invoiceId);
				setSalleId(response?.saleId)
				setLoading(false);
				myCart.setIsAlreadyCreated(true);
			}
		} catch (error) {
			myCart.clearCart(false);
			setPixCode("")
			myCart.setIsAlreadyCreated(false);
			navigation.navigate("Erro", { error });
		}
	};

	useEffect(() => {
		const checkPurchasePayment = async () => {
			if (!salleId) return

			subscribeToEvent(SOCKET_COMMANDS.PAYMENT(salleId), async (data:
				{ saleId: number, status: 'paid' | "canceled" | "expired" }) => {

				if (salleId !== data.saleId) return;

				if (data.status === "canceled") {
					await modal.close();
					navigation.navigate(
						"Result",
						{
							purchaseId,
							success: false,
						}
					);

					myCart.clearCart(false)

					setPixCode("")
					throw new Error();
				} else if (data.status === "paid") {
					await modal.close();
					navigation.navigate(
						"Result",
						{
							purchaseId,
							success: true,
						}
					);

					myCart.clearCart(false)

					setPixCode("")
				};
			})

			return () => {
				unsubscribeFromEvent(SOCKET_COMMANDS.PAYMENT(salleId));
			};
		}

		checkPurchasePayment();
	}, [salleId, isFocused]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (!isFocused || myCart.isAlreadyCreated) return setLoading(false);
			if (cartItems.length > 0) {
				setButtonText("Copiar código PIX")
				generetaPixCode()
				return
			}
			setLoading(false);
		}
		fetchData();
	}, [isFocused, cartItems, myCart.isAlreadyCreated]);

	useEffect(() => {
		if (pixCode !== "") {
			navigation.setOptions({ tabBarStyle: { display: 'none', } });
			return;
		}
		navigation.setOptions({ tabBarStyle: { display: 'flex', height: 80 } });
	}, [pixCode]);

	if (loading) {
		return (
			<View style={{ flex: 1, justifyContent: "center" }}>
				<ActivityIndicator
					size="large"
					color="#30374A"
				/>
			</View>
		)
	}

	return (
		<>
			<Container>
				{!loading && <HeaderNavigation enableButtonLeft={true} enableButtonRight={false} title="Pagamento" />}
				{loading ? (
					<>
						<LoadingPage />
					</>
				) : cartItems.length === 0 && !pixCode ? (
					<EmptyCartContainer>
						<ContainerContentEmptyCart>
							<ContentEmptyCart>
								<EmptyCartIcon source={CartEmpty} />
								<NoItemsText>
									Parece que você ainda não comprou nada
								</NoItemsText>
							</ContentEmptyCart>
							<ContentButtonProducts>
								<ConfirmButton onPress={handleNavigateTo}>
									<TextConfirmButton>
										VER PRODUTOS
									</TextConfirmButton>
								</ConfirmButton>
							</ContentButtonProducts>
						</ContainerContentEmptyCart>
					</EmptyCartContainer>
				) : (
					<Content>
						<Scroll showsVerticalScrollIndicator={false}>
							<PayAtContainer>
								<Title>Total: </Title>
								<ValueSecondary testID="limit_date">
									{NumberToCurrency(
										cartItems.reduce(
											(all, curr) =>
												all +
												(curr.final_price_in_cents /
													100) *
												curr.quantity,
											0
										)
									)}
								</ValueSecondary>
							</PayAtContainer>
							<PixDetailsContainer>
								<Value testID="pix_value">
									Pix Copia e Cola
								</Value>
								<PixLogo
									source={logoPixIcon}
									testID="image"
								/>
								<PixInfo>
									Finalize a compra copiando o código abaixo e
									colando no pix copia e cola do seu
									aplicativo de banco
								</PixInfo>
							</PixDetailsContainer>
							<PixCodeContainer>
								<Label>Código PIX Copia e Cola</Label>
								<PixCodeText testID="pix_code">
									{pixCode}
								</PixCodeText>
							</PixCodeContainer>
							<ButtonPaymentContainer>
								<>
									<ConfirmButton onPress={copyToClipboard}>
										<TextConfirmButton>
											{buttonText}
										</TextConfirmButton>
									</ConfirmButton>
								</>
								<>
									<TextAnother>ou</TextAnother>
								</>
								<>
									<ChangeButton onPress={() => navigation.navigate('Carrinho')}>
										<TextChange>
											Voltar ao carrinho
										</TextChange>
									</ChangeButton>
								</>
							</ButtonPaymentContainer>
						</Scroll>
					</Content>
				)}

				<ModalInfo
					isOpen={modalPaid}
					setIsOpen={setModalPaid}
					title="Pagamento realizado!"
					description="Seu pagamento foi realizado com sucesso e você já pode levar seus produtos!"
					type="success"
					action={modalAction}
					testID="modal_success"
				/>
				<ModalInfo
					isOpen={modalErrorPay}
					setIsOpen={setModalErrorPay}
					title="Pagamento Recusado!"
					description="Seu pagamento não foi efetuado ou o tempo do Pix expirou!"
				/>
			</Container>
		</>
	);
};

export default Payment;